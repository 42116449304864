import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import './i18n'
import { store } from './store'
import { logout, login } from './store/user'
import { Provider } from 'react-redux'
import { getUserLogged } from './api/users'
import { getSenior } from './api/senior'
import { updateCameras } from './store/region'
import Loading from './components/loading/Loading'
import _ from 'lodash'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import * as Sentry from '@sentry/react'
import { getCameras } from './api/cameras'

/*
Sentry.init({
  dsn: 'https://de2ae8fa0b7a212fdc27698090f9647f@o4505929064841216.ingest.sentry.io/4505929068576768',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', `${window.env.REACT_APP_API_URL}`]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: window.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: window.env.REACT_APP_ENV
})
*/

const root = ReactDOM.createRoot(
  document.getElementById('root')
)

let isLoading = true

const setCurrentLoggedInUser = async () => {
  const token = localStorage.getItem('token')
  if (token && !_.isEmpty(token)) {
    try {
      const user = await getUserLogged()
      if (user) {
        if (user.user_type_region) {
          store.dispatch(login({ user: user, senior: null }))
          const cameras = await getCameras()
          store.dispatch(updateCameras(cameras))
        } else {
          const senior = await getSenior()
          store.dispatch(login({ user: user, senior: senior }))
        }
      } else {
        localStorage.removeItem('token')
        store.dispatch(logout())
      }
    } catch (e) {
      localStorage.removeItem('token')
      store.dispatch(logout())
    }
  } else {
    localStorage.removeItem('token')
    store.dispatch(logout())
  }
  isLoading = false
  root.render(
    <Provider store={store}>
      {isLoading ? <Loading /> : <App />}
    </Provider>
  )
}

Promise.all([setCurrentLoggedInUser()])

/*
serviceWorkerRegistration.register()
*/
