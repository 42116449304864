import { configureStore } from '@reduxjs/toolkit'
import userSlice from './store/user'
import snackBarSlice from './store/snackbar'
import regionSlice from './store/region'
// import { comRoom } from './api/com_room'
export const store = configureStore({
  reducer: {
    user: userSlice,
    snackbar: snackBarSlice,
    region: regionSlice
  }
})

/*
store.subscribe(() => {
  const state = store.getState()
  if (state.user.loggedInUser === null) {
    comRoom.disconnect()
  } else {
    comRoom.init(state.user.loggedInUser, `${state.user.id}`, 'user')
  }
})
*/
