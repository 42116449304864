import './AddCardSquare.scss'
import { Grid } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material/'

export default function AddCardSquare (props) {
  // const theme = useTheme();
  const { onClick } = props

  return (
    <Grid container direction='column' justifyContent='space-between' className='addCardContainer'>
      <div className='square'>
        <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end' className='intoSquare'>
          <Grid item container direction='column' justifyContent='center' alignItems='center' className='iconSpace' onClick={onClick}>
            <AddCircleOutline className='addIcon' />
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}
