import './AddCardRectangle.scss'
import { Grid } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material/'

export default function AddCardRectangle (props) {
  // const theme = useTheme();
  const { onClick } = props

  return (
    <Grid container direction='row' justifyContent='center' className='addCardRectangleContainer'>
      <Grid item onClick={onClick}>
        <AddCircleOutline className='addIcon' />
      </Grid>
    </Grid>
  )
}
