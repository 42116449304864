import { useState } from 'react'
import './Header.scss'
import { Link, useNavigate } from 'react-router-dom'
import {
  Typography, Toolbar, Box, AppBar, Button, Avatar,
  useMediaQuery, Menu, MenuItem, Tooltip, IconButton, Grid
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
// import { comRoom } from '../../api/com_room'
import { logout } from '../../store/user'
import { useTranslation } from 'react-i18next'
import { /* LogoutOutlined, */ AccountCircle, ContactMail, FamilyRestroom } from '@mui/icons-material/'
// import VideoCallAgent from '../videoCall/VideoCallAgent'

export default function Header () {
  const { t } = useTranslation()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const dispatch = useDispatch()
  const matches = useMediaQuery('(max-width:780px)')
  const loggedInUser = useSelector(state => state.user.loggedInUser)
  const navigate = useNavigate()
  // const logo = '/family/logoViktor.png'
  const logo = '/family/logo_white_sunday_180x.webp'

  const handleLogout = () => {
    // comRoom.disconnect()
    dispatch(logout())
  }

  const goToProfile = () => {
    navigate('/profile')
  }

  return (
    <Box sx={{ flexGrow: 1 }} className='headerContainer'>
      <AppBar position='static' className='appBar'>
        <Toolbar className='toolbar'>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item sm={3} xs={0} />
            <Grid item xs={6} style={{ textAlign: matches ? 'left' : 'center' }}>
              <Link to='/'><img src={logo} alt='Logo' className='logo' /></Link>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'right', paddingRight: '5px' }}>
              {loggedInUser
                ? (
                  <>
                    {loggedInUser.user_type === 0
                      ? <Button
                          sx={{ flexGrow: 0 }}
                          color='inherit'
                          component={Link}
                          startIcon={<ContactMail className='icon' />}
                          to='/users'
                          className='login'
                        />
                      : <Button
                          sx={{ flexGrow: 0 }}
                          color='inherit'
                          component={Link}
                          startIcon={<FamilyRestroom className='icon' />}
                          to='/region'
                          className='login'
                        />}
                    <Tooltip title={t('navbar.userMenu.tooltip')}>
                      <IconButton onClick={(e) => setAnchorElUser(e.currentTarget)} sx={{ p: 0 }} className='userButton'>
                        <div className='avatarContent'>
                          <Avatar
                            className='avatar'
                            sx={{ width: 24, height: 24 }}
                            alt={`${loggedInUser.firstname} ${loggedInUser.lastName}`}
                            src={loggedInUser.avatar}
                          />
                        </div>

                        {!matches && <span className='name'>{loggedInUser.firstname}</span>}
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '45px' }}
                      id='menu-appbar'
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={() => setAnchorElUser(null)}
                    >
                      {loggedInUser.user_type === 0 &&
                        <MenuItem onClick={() => setAnchorElUser(null)}>
                          <Typography textAlign='center' onClick={goToProfile}>{t('navbar.profile')}</Typography>
                        </MenuItem>}
                      <MenuItem onClick={() => setAnchorElUser(null)}>
                        <Typography textAlign='center' onClick={handleLogout}>{t('navbar.logout')}</Typography>
                      </MenuItem>
                    </Menu>
                  </>
                  )
                : (
                  <Button
                    sx={{ flexGrow: 0 }}
                    color='inherit'
                    component={Link}
                    startIcon={<AccountCircle className='icon' />}
                    to='/login'
                    className='login'
                  >{!matches && t('navbar.login')}
                  </Button>)}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/*
       <VideoCallAgent />
       */}
    </Box>
  )
}
