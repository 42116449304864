import {
  Dialog, DialogContent, DialogContentText, Button, useMediaQuery, Grid
} from '@mui/material/'
import DialogTitle from '../DialogTitle/DialogTitle'
import { useTranslation } from 'react-i18next'
import './DialogAreYouSure.scss'

export default function DialogAreYouSure (props) {
  const matches = useMediaQuery('(max-width:780px)')
  const { t } = useTranslation()
  const { open, setOpen, title, content, onClick, disabledYes, disabledNo } = props

  return (
    <Dialog
      open={open} onClose={setOpen}
      fullWidth
      maxWidth='sm'
      fullScreen={matches}
      className='dialogAreYouSure'
    >
      <DialogTitle title={title} onClose={setOpen} />
      <DialogContent>
        <DialogContentText className='content'>
          {content}
          <div className='button'>
            <Grid container alignItems='center' justifyContent='center' spacing={2}>
              <Grid item>
                <Button variant='contained' color='primary' onClick={onClick} disabled={disabledYes}>{t('actions.yes')}</Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' onClick={setOpen} disabled={disabledNo}>{t('actions.no')}</Button>
              </Grid>
            </Grid>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
