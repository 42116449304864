import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  colors: {
    primary: 'white',
    secondary: 'blue',
    tertiary: 'red'
  }
})

export default theme
