import './App.scss'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/login/LoginPage'
import Header from './components/header/Header'
/*
import HomePage from './pages/home/HomePage'
import MediasPage from './pages/medias/MediasPage'
import PhotosPage from './pages/photos/PhotosPage'
import MusicsPage from './pages/musics/MusicsPage'
import MoviesPage from './pages/movies/MoviesPage'
import EventsPage from './pages/events/EventsPage'
import MessagesPage from './pages/messages/MessagesPage'
import EpisodesPage from './pages/episodes/EpisodesPage'
import VisioPage from './pages/visio/VisioPage'
import IncomingVideocallPage from './pages/videocall/Incoming'
import OutgoingVideocallPage from './pages/videocall/Outgoing'
import SettingsPage from './pages/settings/SettingsPage'
*/
import ProfilePage from './pages/profile/ProfilePage'
import InvitationPage from './pages/invitation/InvitationPage'
import ForgotPasswordPage from './pages/forgotPassword/ForgotPasswordPage'
import ResetPasswordPage from './pages/resetPassword/ResetPasswordPage'
import Footer from './components/footer/Footer'
import { ThemeProvider } from '@mui/material'
import PrivateRoute from './components/routes/PrivateRoute'
import GuestRoute from './components/routes/GuestRoute'
import RegionRoute from './components/routes/RegionRoute'
import theme from './theme'
import SnackBar from './components/snackbar/Snackbar'
import CamerasPage from './pages/cameras/CamerasPage'
import CameraDetailPage from './pages/cameraDetail/CameraDetailPage'
import HomeSundayPage from './pages/homeSunday/HomeSundayPage'
import UsersPage from './pages/users/UsersPage'
import RegionPage from './pages/region/RegionPage'

function App () {
  return (
    <ThemeProvider theme={theme}>
      <div id='page-container'>
        <Router basename='/family'>
          <Header />
          <div id='content-wrap'>
            <Routes>
              <Route index path='login' element={<GuestRoute> <LoginPage />  </GuestRoute>} />
              <Route path='/region' element={<RegionRoute> <RegionPage />  </RegionRoute>} />
              <Route path='/cameras' element={<RegionRoute> <CamerasPage />  </RegionRoute>} />
              <Route path='/cameras/:id' element={<RegionRoute> <CameraDetailPage />  </RegionRoute>} />
              {/*
              <Route path='/' element={<PrivateRoute> <HomePage />  </PrivateRoute>} />
              */
              }
              <Route path='/' element={<PrivateRoute> <HomeSundayPage />  </PrivateRoute>} />
              {/*
              <Route path='medias' element={<PrivateRoute> <MediasPage />  </PrivateRoute>} />
              <Route path='photos/:id' element={<PrivateRoute> <PhotosPage />  </PrivateRoute>} />
              <Route path='musics/:id' element={<PrivateRoute> <MusicsPage />  </PrivateRoute>} />
              <Route path='movies/:id' element={<PrivateRoute> <MoviesPage />  </PrivateRoute>} />
              <Route path='episodes/:id' element={<PrivateRoute> <EpisodesPage />  </PrivateRoute>} />
              <Route path='events' element={<PrivateRoute> <EventsPage />  </PrivateRoute>} />
              <Route path='messages' element={<PrivateRoute> <MessagesPage />  </PrivateRoute>} />
              <Route path='visio' element={<PrivateRoute> <VisioPage />  </PrivateRoute>} />
              <Route path='videocall/incoming' element={<PrivateRoute> <IncomingVideocallPage />  </PrivateRoute>} />
              <Route path='videocall/outgoing/:userId' element={<PrivateRoute> <OutgoingVideocallPage />  </PrivateRoute>} />
              <Route path='settings' element={<PrivateRoute> <SettingsPage />  </PrivateRoute>} />
              */}
              <Route path='users' element={<PrivateRoute> <UsersPage />  </PrivateRoute>} />
              <Route path='profile' element={<PrivateRoute> <ProfilePage />  </PrivateRoute>} />
              <Route path='users/invitation/:token' element={<GuestRoute> <InvitationPage />  </GuestRoute>} />
              <Route path='forgotPassword' element={<GuestRoute> <ForgotPasswordPage />  </GuestRoute>} />
              <Route path='users/reset_password/:token' element={<GuestRoute> <ResetPasswordPage />  </GuestRoute>} />
            </Routes>
            <SnackBar />
          </div>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
