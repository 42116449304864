import { useState, useEffect } from 'react'
import './CamerasPage.scss'
// import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid, Avatar, Button, Switch, FormControlLabel, FormGroup } from '@mui/material'
// import _ from 'lodash'
// import PictureSquare from '../../components/pictureSquare/PictureSquare'
import { useTranslation } from 'react-i18next'
// import { useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { useDispatch } from 'react-redux'
import { setInfo } from '../../store/snackbar'
import { updateCameras } from '../../store/region'
import { getCameras, updateCamera } from '../../api/cameras'
import { useNavigate } from 'react-router-dom'

export default function CamerasPage () {
  // const matches = useMediaQuery('(max-width:780px)')
  const { t } = useTranslation()
  // const { id } = useParams()
  // const theme = useTheme();
  const navigate = useNavigate()
  const [cameras, setCameras] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveCameraLists () {
      try {
        // replace id by loggedInUser id
        const cameraLists = await getCameras()
        cameraLists.sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        })
        !didCancel && setCameras(cameraLists)
        dispatch(updateCameras(cameraLists))
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'cameras.error.getList', severity: 'error' }))
        dispatch(updateCameras([]))
        setIsLoading(true)
      }
    }

    Promise.all([retrieveCameraLists()])

    return () => { didCancel = true }
  }, [])

  function Iframe (props) {
    return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />)
  }

  const handleEnabled = async (event, camera) => {
    const isEnabled = event.target.checked
    try {
      const camerasTmp = [...cameras]
      const currentCam = camerasTmp.findIndex((c) => c.id === camera.id)
      camerasTmp[currentCam] = camera
      await updateCamera({ id: camera.id, seniorId: camera.senior?.id || null, enabled: isEnabled })
      const cameraLists = await getCameras()
      cameraLists.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
      setCameras(cameraLists)
      dispatch(updateCameras(cameraLists))
      dispatch(setInfo({ open: true, message: isEnabled ? 'cameras.success.enabledCamera' : 'cameras.success.disabledCamera', severity: 'success' }))
    } catch (e) {
      console.log(e)
      dispatch(setInfo({ open: true, message: isEnabled ? 'cameras.error.enabledCamera' : 'cameras.error.disabledCamera', severity: 'error' }))
    }
  }

  const cameraComponent = (camera) => {
    return (
      <Grid item md={4} sm={6} xs={12} key={camera.id}>
        <div className='preview'>
          <div style={{ padding: '16px' }}>
            <h2 className='titleCamera'>{camera.name}</h2>
            {
              camera.enabled &&
                <div style={{ position: 'relative', width: '100%', height: '0px', paddingBottom: '56.25%' }}>
                  <Iframe iframe={`<iframe style='position:absolute; left:0; top:0; width:100%; height:100%' src="${camera.previewUrl}" title="${camera.name}" frameborder="0" allowfullscreen></iframe>`} />
                </div>
            }
            <div style={{ width: 'inherit', textAlign: 'center', padding: '10px' }}>
              {camera.senior
                ? <Grid container spacing={1} alignItems='center' justifyContent='center'>
                  <Grid item>
                    <Avatar alt={`${camera.senior.firstname} ${camera.senior.lastname}`} src={camera.senior.avatar} className='avatar' />
                  </Grid>
                  <Grid item>
                    <p className='seniorName'>{camera.senior.firstname} {camera.senior.lastname}</p>
                  </Grid>
                  </Grid>
                : <p>{t('cameras.noBaby')}</p>}
            </div>
            <Grid container spacing={1} direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                  <Button variant='contained' className='button' onClick={() => navigate(`/cameras/${camera.id}`)}>{t('cameras.see')}</Button>
                </Grid>
                <Grid item>
                  <FormGroup>
                    <FormControlLabel control={<Switch checked={camera.enabled} onChange={(e) => handleEnabled(e, camera)} />} label={camera.enabled ? 'Caméra active' : 'Caméra inactive'} />
                  </FormGroup>
                </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    )
  }

  if (isLoading) {
    return (<Loading />)
  }

  return (
    <div className='camerasPage'>
      <Grid container alignItems='center'>
        <Grid item sm={6} xs={12} className='title'>
          <h1>{t('cameras.title')}</h1>
        </Grid>
      </Grid>

      <Grid container justifyContent='space-between' spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }} style={{ marginBottom: '20px' }}>
        {
          cameras.map((el, i) => cameraComponent(el))
        }
      </Grid>
    </div>
  )
}
