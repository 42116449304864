import { createSlice } from '@reduxjs/toolkit'

const regionSlice = createSlice({
  name: 'region',
  initialState: {
    cameras: [],
    seniors: []
  },
  reducers: {
    updateCameras: (state, action) => {
      state.cameras = action.payload
    },
    updateSeniors: (state, action) => {
      state.seniors = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateCameras, updateSeniors } = regionSlice.actions

export default regionSlice.reducer
