import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedInUser: null,
    senior: null
  },
  reducers: {
    logout: (state) => {
      state.loggedInUser = null
      state.senior = null
      localStorage.removeItem('token')
    },
    login: (state, action) => {
      state.loggedInUser = action.payload.user
      state.senior = action.payload.senior
    },
    update: (state, action) => {
      state.loggedInUser = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { login, logout, update } = userSlice.actions

export default userSlice.reducer
