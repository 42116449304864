import { useState, useEffect } from 'react'
import './HomeSundayPage.scss'
// import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme */, useMediaQuery } from '@mui/material'
// import _ from 'lodash'
import { useTranslation, Trans } from 'react-i18next'
import { getMovies, getAlbumsMovies } from '../../api/movies'
import { PlayCircle } from '@mui/icons-material/'
import Loading from '../../components/loading/Loading'
import { useDispatch } from 'react-redux'
// import DialogAddAlbum from '../../components/dialog/DialogAddAlbum/DialogAddAlbum'
// import DialogAreYouSure from '../../components/dialog/DialogAreYouSure/DialogAreYouSure'
// import DialogAddMovie from '../../components/dialog/DialogAddMovie/DialogAddMovie'
import { setInfo } from '../../store/snackbar'

export default function HomeSundayPage () {
  const matches = useMediaQuery('(max-width:780px)')
  const { t } = useTranslation()
  const [movies, setMovies] = useState([])
  const [movieClicked, setMovieClicked] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [movieList, setMovieList] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveMoviesLists () {
      try {
        const movieLists = await getAlbumsMovies()
        const currentList = movieLists[0]
        if (currentList) {
          !didCancel && setMovieList(currentList)
          setIsLoading(false)
        } else {
          throw new Error('404')
        }
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'movies.error.getList' }))
        setIsLoading(false)
      }
    }

    Promise.all([retrieveMoviesLists()])

    return () => { didCancel = true }
  }, [])

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveMovies () {
      try {
        const moviesTmp = await getMovies({ id: movieList.id })
        !didCancel && setMovies(moviesTmp)
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'movies.error.get' }))
        setIsLoading(false)
      }
    }

    if (movieList) {
      Promise.all([retrieveMovies()])
    }

    return () => { didCancel = true }
  }, [movieList])

  function Iframe (props) {
    return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />)
  }

  const movieComp = (el, i) =>
    <>
      <Grid
        container alignItems='center' justifyContent='flex-start'
        className='movieContainer'
      >
        <Grid
          item xs={2} sm={1} className='numberContainer'
          onClick={() => setMovieClicked(i)}
        >
          {
            movieClicked === i
              ? <PlayCircle fontSize='large' />
              : <span className='number'>{i + 1}</span>
          }
        </Grid>
        <Grid
          item xs={8} sm={10}
          onClick={() => setMovieClicked(i)}
        >
          <p className='title'>{el.name}</p>
        </Grid>
      </Grid>
    </>

  if (isLoading) {
    return (<Loading />)
  }

  if (!isLoading && movies.length === 0) {
    return (
      <div className='homeSundayPage'>
        <h2 className='sectionTitle'>{t('movies.title')}</h2>
        <p className='text'>{t('movies.nodata')}</p>
      </div>
    )
  }

  return (
    <div className='homeSundayPage'>
      <Grid container alignItems='center'>
        <Grid item sm={6} xs={12} className='title' container alignItems='center' justifyContent='center' spacing={1}>
          <Grid item>
            <h2>
              {movieList?.name}
            </h2>
          </Grid>
        </Grid>

      </Grid>
      <h2 className='sectionTitle'>{t('movies.title')}</h2>
      <p className='text'>{t('movies.desc')}</p>
      <Grid container justifyContent='space-between' alignItems={matches ? 'center' : 'flex-start'} direction={matches ? 'column-reverse' : 'row'}>
        <Grid item xs={12} sm={5}>
          {movies.map((el, i) => <>{movieComp(el, i)}</>)}
        </Grid>
        <Grid item xs={12} sm={7} container direction='column' alignItems='center'>
          <div style={{ position: 'relative', width: '100%', height: '0px', paddingBottom: '56.25%' }}>
            <Iframe iframe={`<iframe style='position:absolute; left:0; top:0; width:100%; height:100%' src="${movies[movieClicked]?.url}" title="${movies[movieClicked]?.name}" frameborder="0" allowfullscreen></iframe>`} />
          </div>
          <p><Trans
            i18nKey='movies.count'
            values={{ count: movies.length }}
            components={[]}
             />
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
