import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { de } from './lang/de'
import { fr } from './lang/fr'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: {
    translation: de
  },
  fr: {
    translation: fr
  }
}

export const getLang = () => {
  /*
  const navigatorLang = window.navigator.language
  if (navigatorLang === 'fr' || navigatorLang.startsWith('fr-')) {
    return 'fr'
  }
  if (navigatorLang === 'de' || navigatorLang.startsWith('de-')) {
    return 'de'
  }

  return window.env.REACT_APP_DEFAULT_LANG
  */
  return 'fr'
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
