import { useState, useEffect } from 'react'
import { Paper, Grid, TextField, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import './ResetPasswordPage.scss'
import { updateUserInvited, getUserInvited } from '../../api/users'
import { getSenior } from '../../api/senior'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { REGEX_PASSWORD } from '../../config'
// import _ from 'lodash';
import { login } from '../../store/user'
import Loading from '../../components/loading/Loading'
import { setInfo } from '../../store/snackbar'
export default function ResetPasswordPage (props) {
  const { t } = useTranslation()
  const { token } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [newPassword, setNewPassword] = useState('')
  const [confirmedNewPassword, setConfirmedNewPassword] = useState('')
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveUser () {
      try {
        const user = await getUserInvited(token)
        !didCancel && setUser(user)
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'resetPwd.error.get' }))
        setIsLoading(false)
      }
    }

    Promise.all([retrieveUser()])
    return () => { didCancel = true }
  }, [token, dispatch])

  const handleResetPassword = async () => {
    setIsLoading(true)
    try {
      const userTmp = {
        password: newPassword
      }

      await updateUserInvited(userTmp, user.id, token)
      await localStorage.setItem('token', token)
      const senior = await getSenior()
      dispatch(login({ user: user, senior: senior }))
      navigate('/medias')
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'resetPwd.error.update' }))
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return (<Loading />)
  }

  return (
    <Grid container justifyContent='center' alignItems='center' className='resetPasswordPage'>
      <Grid item xs={12} sm={8}>
        <Paper className='paper'>
          <h1>{t('resetPwd.title')}</h1>
          <TextField
            id='newPassword'
            label={t('form.newPassword.label')}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            fullWidth
            required
            margin='normal'
            className='input'
            helperText={!!newPassword && !REGEX_PASSWORD.test(newPassword) ? t('form.newPassword.helper') : ''}
            error={!REGEX_PASSWORD.test(newPassword)}
            variant='outlined'
            type='password'
          />
          <TextField
            id='confirmedNewPassword'
            label={t('form.confirmedNewPassword.label')}
            value={confirmedNewPassword}
            onChange={(event) => setConfirmedNewPassword(event.target.value)}
            fullWidth
            required
            margin='normal'
            className='input'
            helperText={!!confirmedNewPassword && (!REGEX_PASSWORD.test(confirmedNewPassword) || confirmedNewPassword !== newPassword) ? t('form.confirmedNewPassword.helper') : ''}
            error={!REGEX_PASSWORD.test(confirmedNewPassword) || confirmedNewPassword !== newPassword}
            variant='outlined'
            type='password'
          />
          <div style={{ textAlign: 'center' }}>
            <Button
              disabled={
                            !newPassword ||
                            (!!newPassword && !REGEX_PASSWORD.test(newPassword)) ||
                            !confirmedNewPassword ||
                            (!!confirmedNewPassword && !REGEX_PASSWORD.test(confirmedNewPassword)) ||
                            confirmedNewPassword !== newPassword
                        }
              variant='contained'
              onClick={handleResetPassword}
            >
              {t('resetPwd.button')}
            </Button>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <NavLink to='/login' className='link'>
              {t('login.button')}
            </NavLink>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
