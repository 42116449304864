import { useState } from 'react'
import './DialogAddSenior.scss'
import {
  Dialog, DialogContent, DialogContentText, Button, TextField, useMediaQuery
} from '@mui/material/'
import DialogTitle from '../DialogTitle/DialogTitle'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { createSenior, getSeniors } from '../../../api/senior'
import { createUser } from '../../../api/users'
import { updateSeniors } from '../../../store/region'
import { setInfo } from '../../../store/snackbar'
import { useDispatch } from 'react-redux'

export default function DialogAddSenior (props) {
  // const matches = useMediaQuery('(max-width:780px)');
  const { open, setOpen, title } = props
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameFamily, setFirstnameFamily] = useState('')
  const [lastnameFamily, setLastnameFamily] = useState('')
  const [emailFamily, setEmailFamily] = useState('')
  const matches = useMediaQuery('(max-width:780px)')
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleUpdateSenior = async () => {
    try {
      const newSenior = await createSenior({ firstname, lastname })
      const newUser = {
        firstname: firstnameFamily,
        lastname: lastnameFamily,
        email: emailFamily,
        account_id: newSenior.id,
        phone: '',
        avatar: ''
      }
      await createUser(newUser)
      const seniors = await getSeniors()
      dispatch(updateSeniors(seniors))
      setOpen(false)
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'region.seniors.error.create', severity: 'error' }))
    }
  }

  return (
    <Dialog
      open={open} onClose={() => setOpen(false)}
      fullWidth
      maxWidth='sm'
      fullScreen={matches}
      className='dialogAddSeniorContainer'
    >
      <DialogTitle title={title} onClose={() => setOpen(false)} />
      <DialogContent>
        <DialogContentText>
          <TextField
            id='filled-basic' label={t('region.seniors.add.label.firstname')} variant='filled'
            required
            fullWidth
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <TextField
            id='filled-basic' label={t('region.seniors.add.label.lastname')} variant='filled'
            required
            fullWidth
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <TextField
            id='filled-basic' label={t('region.seniors.add.label.firstnameFamily')} variant='filled'
            required
            fullWidth
            value={firstnameFamily}
            onChange={(e) => setFirstnameFamily(e.target.value)}
          />
          <TextField
            id='filled-basic' label={t('region.seniors.add.label.lastnameFamily')} variant='filled'
            required
            fullWidth
            value={lastnameFamily}
            onChange={(e) => setLastnameFamily(e.target.value)}
          />
          <TextField
            id='filled-basic' label={t('region.seniors.add.label.emailFamily')} variant='filled'
            required
            fullWidth
            value={emailFamily}
            onChange={(e) => setEmailFamily(e.target.value)}
          />
          <div className='button'>
            <Button variant='contained' disabled={_.isEmpty(firstname) || _.isEmpty(lastname) || _.isEmpty(firstnameFamily) || _.isEmpty(lastnameFamily) || _.isEmpty(emailFamily)} color='primary' onClick={handleUpdateSenior}>{t('actions.create')}</Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
