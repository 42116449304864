import axios from 'axios'
import { RESET_PASSWORD, API_URL, PUBLIC_URL } from '../config'

export const getToken = async (mail, password) => {
  try {
    const response = await axios.get(`${API_URL}/users/me/token`, {
      auth: {
        username: mail,
        password: password
      }
    })
    const res = { token: response.data.token }
    await localStorage.setItem('token', res.token)
    return res
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getUserInvited = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/users/me`, {
      headers: {
        'X-Password-Token': token
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getUserLogged = async () => {
  try {
    const response = await axios.get(`${API_URL}/users/me`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/users/`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.users
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getUser = async ({ id }) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createUser = async (newUser) => {
  try {
    const response = await axios.post(`${API_URL}/users`, newUser, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateUser = async (userData, id) => {
  try {
    const response = await axios.put(`${API_URL}/users/${id}`, userData, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateUserInvited = async (userData, id, token) => {
  try {
    const response = await axios.put(`${API_URL}/users/${id}`, userData, {
      headers: {
        'X-Password-Token': token,
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/users/password_reset`,
      {
        email: email,
        url: `${PUBLIC_URL}`
      }, {
        headers: {
          'X-Password-Token': localStorage.getItem('token'),
          'X-App-Key': RESET_PASSWORD,
          'Content-Type': 'application/json'
        }
      })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
