import './Footer.scss'
import { Grid } from '@mui/material'
import { Trans } from 'react-i18next'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

export default function Footer () {
  return (
    <Grid container id='footer' justifyContent='center' alignItems='center'>
      <Grid item className='copyright'>
        <Trans i18nKey='footer.copyright' components={[<FavoriteBorderIcon className='copyrightIcon' />]} />
      </Grid>
    </Grid>
  )
}
