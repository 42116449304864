import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Snackbar, Alert as MuiAlert } from '@mui/material'
import { setOpen } from '../../store/snackbar'
import { useTranslation } from 'react-i18next'

const Alert = React.forwardRef(function Alert (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} className='snackbar' />
})

export default function SnackBar () {
  const snackbarInfo = useSelector(state => state.snackbar)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setOpen(false))
  }

  return (
    <Snackbar
      open={snackbarInfo.open} autoHideDuration={6000} onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={snackbarInfo.severity} sx={{ width: '100%' }}>
        {t(snackbarInfo.message)}
      </Alert>
    </Snackbar>
  )
}
