export const fr = {
  region: {
    title: 'Administration',
    seniors: {
      title: 'Liste des bébés',
      empty: 'La liste des comptes bébé est vide',
      add: {
        button: 'Ajouter un bébé',
        label: {
          firstname: 'Prénom du bébé',
          lastname: 'Nom du bébé',
          firstnameFamily: 'Prénom du parent',
          lastnameFamily: 'Nom du parent',
          emailFamily: 'Email du parent'
        }
      },
      delete: {
        title: 'Supprimer le compte du bébé'
      },
      error: {
        create: 'Une erreur est survenue à la création d\'un compte bébé',
        delete: 'Une erreur est survenue à la suppression d\'un compte bébé',
        getDetail: 'Une erreur est survenue à la récupération de la liste des bébés'
      }
    }
  },
  loading: {
    title: 'Enregistrement en cours',
    text: 'Veuillez patienter pendant la capture de 10s de video'
  },
  home: {
    visio: 'Appel vidéo',
    messages: 'Messages',
    rdv: 'Agenda',
    medias: 'Médias',
    contacts: 'Contacts',
    settings: 'Paramètres'
  },
  medias: {
    album: {
      count: '{{count}} élément(s)',
      add: 'Ajouter',
      private: 'privé'
    },
    list: {
      seeMore: 'voir plus',
      seeLess: 'voir moins',
      photos: 'Albums photo',
      musics: 'Playlists',
      movies: 'Vidéothèques',
      audioseries: 'Livres/Series audio'
    },
    title: 'médias',
    addAudioserie: {
      label: {
        url: 'Adresse du livre ou de la série audio (URL)'
      }
    },
    addAlbum: {
      photos: 'Ajouter un album photo',
      musics: 'Ajouter une playlist',
      movies: 'Ajouter une vidéothèque',
      audioserie: 'Ajouter un livre ou une serie audio',
      label: {
        name: 'Nom de l\'album',
        private: 'Rendre privé ?'
      },
      updatePhoto: 'Modifier l\'image de couverture',
      addPhoto: 'Ajouter une image de couverture',
      deletePhoto: 'Supprimer l\'image de couverture'
    },
    editAlbum: {
      photos: 'Modifier l\'album photo',
      musics: 'Modifier la playlist',
      movies: 'Modifier la vidéothèque'
    },
    deleteAlbum: {
      photos: 'Supprimer l\'album photo',
      musics: 'Supprimer l\'album de musique',
      movies: 'Supprimer l\'album de film',
      audioserie: 'Supprimer l\'audioserie'
    },
    error: {
      getPhotoAlbums: 'Une erreur est survenue à la récupération des albums photos',
      addPhotoAlbum: 'Une erreur est survenue à la création de l\'album photo',
      getMusicLists: 'Une erreur est survenue à la récupération des playlists',
      addMusicList: 'Une erreur est survenue à la création de la playlist',
      getMovieLists: 'Une erreur est survenue à la récupération des vidéothèques',
      addMovieList: 'Une erreur est survenue à la création de la vidéothèque',
      getAudioseries: 'Une erreur est survenue à la récupération des livres et séries audio',
      addAudioserie: 'Une erreur est survenue à la création du livre ou de la série audio',
      delete_photos: 'Une erreur est survenue à la suppression de l\'album photo',
      delete_musics: 'Une erreur est survenue à la suppression de la playlist',
      delete_movies: 'Une erreur est survenue à la suppression de la vidéothèque',
      delete_episodes: 'Une erreur est survenue à la suppression du livre ou de la série audio'

    }
  },
  photos: {
    title: 'Photos',
    dragAndDrop: 'Glisser - déposer vos photos pour les ajouter à l’album',
    delete: 'Supprimer la photo',
    error: {
      get: 'Une erreur est survenue à la récupération des photos',
      getAlbum: 'Une erreur est survenue à la récupération de l\'album photo',
      update: 'Une erreur est survenue à la sauvegarde des photos',
      updateAlbum: 'Une erreur est survenue à la mise à jour de l\'album photo',
      delete: 'Une erreur est survenue à la suppression de la photo'
    }
  },
  musics: {
    title: 'Musiques',
    desc: 'Cliquez sur une musique pour lancer l’écoute',
    add: {
      title: 'Ajouter une musique',
      name: 'Titre de la musique',
      artist: 'Nom de l\'artiste',
      url: 'Lien de la musique'
    },
    delete: {
      title: 'Supprimer la musique'
    },
    emptyList: 'La playlist est vide, ',
    count: '{{count}} musique(s)',
    error: {
      get: 'Une erreur est survenue à la récupération des musiques',
      getList: 'Une erreur est survenue à la récupération de la playlist',
      add: 'Une erreur est survenue à l\'ajout d\'une musique',
      updateList: 'Une erreur est survenue à la mise à jour de playlist',
      delete: 'Une erreur est survenue à la suppression d\'une musique'
    }
  },
  movies: {
    title: 'Film',
    desc: 'Cliquez sur un film pour lancer le visionnage',
    nodata: 'Aucune donnée n\'est disponible pour le moment',
    add: {
      title: 'Ajouter un film',
      name: 'Titre du film',
      url: 'Lien du film'
    },
    delete: {
      title: 'Supprimer le film'
    },
    emptyList: 'La vidéothèque est vide, ',
    count: '{{count}} film(s)',
    error: {
      get: 'Une erreur est survenue à la récupération des films',
      getList: 'Une erreur est survenue à la récupération de la vidéothèque',
      add: 'Une erreur est survenue à l\'ajout d\'un film',
      updateList: 'Une erreur est survenue à la mise à jour de vidéothèque',
      delete: 'Une erreur est survenue à la suppression d\'un film'
    }
  },
  episodes: {
    title: 'Épisodes',
    desc: 'Cliquez sur un épisode pour lancer l’écoute',
    emptyList: 'La liste d\'épisodes est vide, ',
    count: '{{count}} épisode(s)',
    error: {
      get: 'Une erreur est survenue à la récupération des épisodes',
      getList: 'Une erreur est survenue à la récupération de la liste d\'épisodes'
    },
    open_website: 'Voir sur le site ARD Audiothek'
  },
  events: {
    title: 'Agenda',
    other: '{{count}} autres(s)',
    new: {
      title: 'Créer un nouveau rendez-vous'
    },
    edit: {
      title: 'Modifier un rendez-vous'
    },
    delete: {
      title: 'Supprimer le rendez-vous'
    },
    all: {
      title: 'Liste des rendez-vous'
    },
    fields: {
      name: 'Nom du rendez-vous',
      date: 'Date'
    },
    add: 'Créer',
    error: {
      get: 'Une erreur est survenue à la récupération des rendez-vous',
      create: 'Une erreur est survenue à l\'ajout du rendez-vous',
      update: 'Une erreur est survenue à la mise à jour du rendez-vous',
      delete: 'Une erreur est survenue à la suppression du rendez-vous'
    }
  },
  users: {
    title: 'Contacts',
    new: {
      title: 'Inviter un utilisateur',
      invite: 'Inviter'
    },
    me: 'moi',
    error: {
      get: 'Les contacts ne sont pas accessibles pour le moment',
      create: 'Une erreur est survenue à l\'invitation du contact'
    }
  },
  messages: {
    title: 'Messages',
    add: {
      title: 'Nouveau message',
      subject: 'Sujet',
      body: 'Corps du message'
    },
    edit: {
      title: 'Modifier le message',
      updatedAt: 'Envoyé le {{date}} à {{time}}',
      readAt: 'Lu le {{date}} à {{time}}',
      notRead: 'Non lu'
    },
    delete: {
      title: 'Supprimer le message'
    },
    empty: 'Aucun message n\'a été envoyé à {{firstname}}, cliquez sur <0>"Nouveau message"</0> dès à présent !',
    emptyDefault: 'votre proche',
    error: {
      get: 'Une erreur est survenue à la récupération des messages',
      send: 'Une erreur est survenue à l\'envoie du message',
      update: 'Une erreur est survenue à la mise à jour du message',
      delete: 'Une erreur est survenue à la suppression du message'
    }
  },
  visio: {
    title: 'Appel vidéo',
    copy: 'Copier le lien d\'invitation',
    boxConnected: 'La box est connectée.',
    boxDisconnected: 'La box est déconnectée.',
    call: 'Appeler',
    error: {
      get: 'L\'appel vidéo n\'est pas disponible pour le moment'
    },
    calling_you: '{{firstname}} vous appelle',
    accept: 'Accepter',
    refuse: 'Refuser',
    missed_call: 'Appel de {{firstname}} manqué',
    seen: 'C\'est noté'
  },
  profile: {
    title: 'Mon profil',
    save: 'Enregistrer',
    password: 'Mon mot de passe',
    error: {
      update: 'Une erreur est survenue à la mise à jour du profil',
      updatePwd: 'Une erreur est survenue à la mise à jour du mot de passe'
    }
  },
  invitation: {
    title: 'Créer mon compte',
    save: 'Enregistrer',
    error: {
      get: 'Une erreur est survenue à la récupération du compte',
      update: 'Une erreur est survenue à la mise à jour du compte'
    }
  },
  login: {
    title: 'Bienvenue sur le portail Sunday Junior',
    text: 'Merci de vous identifier.',
    button: 'Se connecter',
    forgotPwd: 'Mot de passe oublié',
    error: {
      login: 'Connexion impossible, merci de vérifier vos identifiants'
    }
  },
  forgotPwd: {
    title: 'Mot de passe oublié',
    button: 'Réinitialiser',
    text: 'Si l\'email est bien enregistré, vous recevrez dans quelques instants un lien de réinitialisation',
    error: {
      send: 'Une erreur est survenue à la demande de réinitialisation'
    }
  },
  resetPwd: {
    title: 'Réinitialiser le mot de passe',
    button: 'Réinitialiser',
    error: {
      get: 'Une erreur est survenue à la récupération du compte invité',
      update: 'Une erreur est survenue à la mise à jour du mot de passe'
    }
  },
  settings: {
    title: 'Paramètres',
    notifications: {
      title: 'Notifications',
      text: `La box installée chez votre proche reçoit parfois des notifications. 
            Ça peut-être des notifications d'appels vidéo, des rappels de RDV, des alertes importantes du département, ou une confirmation de lancement du SOS. 
            Vous pouvez configurer la plage horaire sur laquelle votre proche peut recevoir ces notifications, afin de ne pas la déranger à des moments innoportuns.`,
      time: 'Plage horaire active (pendant laquelle le proche va recevoir les notifications)',
      mute: 'Mode "ne pas déranger" (votre proche ne recevra jamais les notifications)',
      error: {
        get: 'Une erreur est survenue à la récupération des informations du patient',
        update: 'Une erreur est survenue à la mise à jour des notifications'
      }
    },
    sosContact: {
      title: 'Configuration SOS',
      text: 'La Box Viktor vous permet de faire une demande de contact par l\'envoi d\'un mail à vos proches.',
      add: {
        title: 'Nouveau contact SOS'
      },
      edit: {
        title: 'Modifier le contact SOS'
      },
      delete: {
        title: 'Supprimer le contact SOS'
      },
      error: {
        get: 'Une erreur est survenue à la récupération des contacts SOS',
        add: 'Une erreur est survenue à l\'ajout du contact SOS',
        update: 'Une erreur est survenue à la mise à jour du contact SOS',
        delete: 'Une erreur est survenue à la suppression du contact SOS'
      }
    }
  },
  cameras: {
    title: 'Caméras',
    see: 'Voir',
    record: {
      title: 'Enregistrement',
      button: 'Lancer l\'enregistrement',
      error: 'Une erreur est survenue lors de l\'enregistrement'
    },
    switchBaby: {
      button: 'Changer de bébé',
      title: 'Associer un autre bébé',
      text: 'Souhaitez-vous associer le bébé <0>{{firstname}} {{lastname}}</0> pour la caméra <0>{{cameraName}} ?</0>',
      undo: 'Souhaitez-vous désassigner la caméra <0>{{cameraName}} ?</0> Elle ne sera plus associé à aucun bébé'
    },
    preview: {
      link: 'Votre navigateur ne prend pas en charge les vidéos HTML5. Voici <0>un lien pour télécharger la vidéo</0>.',
      title: 'Prévisualiser',
      validate: 'Voulez-vous prendre une vidéo pour <0>{{firstname}} {{lastname}}</0> ?'
    },
    noBaby: 'Pas de bébé associé',
    addBaby: 'Associer un bébé',
    baby: {
      title: 'Détail du bébé',
      none: 'Aucun bébé'
    },
    error: {
      getDetail: 'Une erreur est survenue à la récupération de la caméra',
      getList: 'Une erreur est survenue à la récupération de la liste des caméras',
      switchBaby: 'Une erreur est survenue à l\'association',
      record: 'Une erreur est survenue lors de l\'enregistrement',
      disabledCamera: 'Une erreur est survenue lors de la désactivation de la caméra',
      enabledCamera: 'Une erreur est survenue lors de l\'activation de la caméra'
    },
    success: {
      switchBaby: 'L\'association à été réalisé avec succès',
      record: 'L\'enregistrement à été sauvegardé avec succès',
      disabledCamera: 'La caméra a été correctement désactivée',
      enabledCamera: 'La caméra a été correctement activée'
    }
  },
  form: {
    phone: {
      label: 'Téléphone mobile',
      helper: 'Le numéro de téléphone doit contenir l\'indicatif pays (+33601020304)'
    },
    mail: {
      label: 'Email',
      helper: 'L\'adresse mail est requise et doit être valide (exemple@exemple.com)'
    },
    password: {
      label: 'Mot de passe',
      helper: 'Le mot de passe est requis et dois contenir 8 charactères dont: 1 nombre, 1 majuscule, 1 minuscule'
    },
    newPassword: {
      label: 'Nouveau mot de passe',
      helper: 'Le nouveau mot de passe est requis et dois contenir 8 charactères dont: 1 nombre, 1 majuscule, 1 minuscule'
    },
    confirmedNewPassword: {
      label: 'Confirmer nouveau mot de passe',
      helper: 'Les mots de passe ne correspondent pas'
    },
    firstname: {
      label: 'Prénom',
      helper: 'Le prénom est requis'
    },
    lastname: {
      label: 'Nom',
      helper: 'Le nom est requis'
    },
    isContactMeRecipient: {
      label: 'Destinataire des messages de la fonction "Contactez-moi"'
    },
    startAt: {
      label: 'Heure de début'
    },
    endAt: {
      label: 'Heure de fin'
    }
  },
  actions: {
    create: 'Créer',
    edit: 'Modifier',
    yes: 'Oui',
    no: 'Non',
    areYouSure: 'Êtes vous sûr ?',
    goBack: 'retour',
    goBackMedias: 'retour aux médias'
  },
  navbar: {
    login: 'Se connecter',
    logout: 'Se déconnecter',
    profile: 'Mon profil',
    userMenu: {
      tooltip: 'Mon compte'
    }
  },
  footer: {
    copyright: 'Fabriqué avec <0/> par Fingertips'
  },
  error: {
    default: 'Une erreur est survenue'
  }
}
