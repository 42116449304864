import {
  Dialog, DialogContent, DialogContentText, useMediaQuery
} from '@mui/material/'
import DialogTitle from '../DialogTitle/DialogTitle'
import Loading from '../../loading/Loading'
import './DialogLoading.scss'

export default function DialogLoading (props) {
  const matches = useMediaQuery('(max-width:780px)')
  const { open, title, content } = props

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      fullScreen={matches}
      className='dialogLoading'
    >
      <DialogTitle title={title} />
      <DialogContent>
        <DialogContentText className='content'>
          {content}
          <Loading />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
