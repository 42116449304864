import { useState, useEffect } from 'react'
import './ProfilePage.scss'
import { Grid/*, useTheme, useMediaQuery */, Avatar, TextField, Button, Fab, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { KeyboardDoubleArrowLeft, AddAPhoto } from '@mui/icons-material/'
// import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser, getToken } from '../../api/users'
import Loading from '../../components/loading/Loading'
import { update } from '../../store/user'
import _ from 'lodash'
import { REGEX_MAIL, REGEX_PASSWORD } from '../../config'
import { setInfo } from '../../store/snackbar'

export default function ProfilePage () {
  // const matches = useMediaQuery('(max-width:780px)');
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loggedInUser = useSelector(state => state.user.loggedInUser)
  const [firstname, setFirstname] = useState(loggedInUser.firstname)
  const [lastname, setLastname] = useState(loggedInUser.lastname)
  const [email, setEmail] = useState(loggedInUser.email)
  const [isContactMeRecipient, setIsContactMeRecipient] = useState(loggedInUser.is_contact_me_recipient)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [previewUrl, setPreviewUrl] = useState(loggedInUser.avatar)
  const [isLoading, setIsLoading] = useState(false)
  const [inputChange, setInputChange] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isLoading) {
    return (<Loading />)
  }

  const handleChangePicture = async (event) => {
    const {
      target: {
        files: [file]
      }
    } = event

    const reader = new FileReader()

    reader.onload = function (e) {
      setPreviewUrl(e.target.result)
      setInputChange(true)
    }

    reader.readAsDataURL(file) // convert to base64 string
  }

  const onUpdateUser = async () => {
    setIsLoading(true)
    try {
      const userTmp = {
        firstname, lastname, email, is_contact_me_recipient: isContactMeRecipient
      }

      if (!previewUrl?.startsWith('http') && previewUrl !== '/avatar.svg') {
        userTmp.avatar = previewUrl
      }

      await updateUser(userTmp, loggedInUser.id)
      userTmp.id = loggedInUser.id
      userTmp.user_type_region = false
      dispatch(update({ ...loggedInUser, ...userTmp }))
      setInputChange(false)
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'profile.error.update' }))
    }
    setIsLoading(false)
  }

  const onUpdatePwd = async () => {
    setIsLoading(true)

    // check token with email and password first
    // if true, update pwd
    try {
      await getToken(email, password)
      const userTmp = {
        password: newPassword
      }

      await updateUser(userTmp, loggedInUser.id)
      setPassword('')
      setNewPassword('')
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'profile.error.updatePwd' }))
    }
    setIsLoading(false)
  }

  return (
    <div className='profilePage'>
      <Grid container alignItems='center'>
        <Grid item sm={3} xs={12}>
          <Grid container justifyContent='flex-start' alignItems='center' className='goBack'>
            <KeyboardDoubleArrowLeft className='icon' />
            <Link to='/'>{t('actions.goBack')}</Link>
          </Grid>
        </Grid>

        <Grid item sm={6} xs={12} className='title'>
          <h1>{t('profile.title')}</h1>
        </Grid>
      </Grid>
      <Grid container alignItems='center'>
        <Grid item sm={5} xs={12} container direction='column' justifyContent='space-around' alignItems='center'>
          <Grid item>
            <div className='avatarContent'>
              <Avatar
                className='avatar'
                sx={{ width: 200, height: 200 }}
                alt={`${loggedInUser.firstname} ${loggedInUser.lastname}`}
                src={previewUrl}
              />
            </div>

          </Grid>
          <Grid item>
            <Fab size='small' className='upload' onClick={() => { }}>
              <input accept='image/*' style={{ display: 'none' }} id='icon-button-file' type='file' onChange={event => handleChangePicture(event)} />
              <label htmlFor='icon-button-file'>
                <AddAPhoto />
              </label>
            </Fab>
          </Grid>
        </Grid>
        <Grid item sm={7} xs={12} container alignItems='center' className='info'>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.firstname.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='firstname'
              value={firstname}
              onChange={(event) => { setFirstname(event.target.value); setInputChange(true) }}
              fullWidth
              required
              margin='normal'
              className='input'
                            /* helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                            error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                            onKeyPress={onKeyPressMail} */
              variant='outlined'
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.lastname.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='lastname'
              value={lastname}
              onChange={(event) => { setLastname(event.target.value); setInputChange(true) }}
              fullWidth
              required
              margin='normal'
              className='input'
                            /* helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                            error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                            onKeyPress={onKeyPressMail} */
              variant='outlined'
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.mail.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='email'
              value={email}
              onChange={(event) => { setEmail(event.target.value); setInputChange(true) }}
              fullWidth
              required
              margin='normal'
              className='input'
              helperText={!!email.trim() && !REGEX_MAIL.test(email.trim()) ? t('form.mail.helper') : ''}
              error={!!email.trim() && !REGEX_MAIL.test(email.trim())}
              variant='outlined'
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.isContactMeRecipient.label')}</Grid>
          <Grid item sm={8} xs={12}>
            <Checkbox
              checked={isContactMeRecipient}
              onChange={(e) => { setIsContactMeRecipient(e.target.checked); setInputChange(true) }}
            />
          </Grid>
          <Grid
            item xs={12}
            className='button'
          >
            <Button
              variant='contained'
              onClick={onUpdateUser}
              disabled={
                                !inputChange ||
                                _.isEmpty(firstname) ||
                                _.isEmpty(lastname) ||
                                _.isEmpty(email) ||
                                !REGEX_MAIL.test(email.trim()) ||
                                /* || _.isEmpty(password)
                                || !REGEX_PASSWORD.test(password) */
                                _.isEmpty(email) ||
                                !REGEX_MAIL.test(email.trim())
                            }
            >
              {t('profile.save')}
            </Button>
          </Grid>

        </Grid>

      </Grid>
      <Grid container alignItems='center'>
        <Grid item sm={3} xs={12} />

        <Grid item sm={6} xs={12} className='title'>
          <h1>{t('profile.password')}</h1>
        </Grid>
      </Grid>
      <Grid container alignItems='center'>
        <Grid item sm={5} xs={12} container direction='column' justifyContent='space-around' alignItems='center' />
        <Grid item sm={7} xs={12} container alignItems='center' className='info'>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.password.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              required
              margin='normal'
              className='input'
              helperText={!!password.trim() && !REGEX_PASSWORD.test(password) ? t('form.password.helper') : ''}
              error={!!password.trim() && !REGEX_PASSWORD.test(password)}
              variant='outlined'
              autoFocus
              type='password'
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.newPassword.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='newPassword'
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              fullWidth
              required
              margin='normal'
              className='input'
              helperText={!!newPassword.trim() && !REGEX_PASSWORD.test(newPassword) ? t('form.newPassword.helper') : ''}
              error={!!newPassword.trim() && !REGEX_PASSWORD.test(newPassword)}
              variant='outlined'
              autoFocus
              type='password'
            />
          </Grid>
          <Grid
            item xs={12}
            className='button'
          >
            <Button
              variant='contained'
              onClick={onUpdatePwd}
              disabled={
                                _.isEmpty(password) ||
                                !REGEX_PASSWORD.test(password) ||
                                _.isEmpty(newPassword) ||
                                !REGEX_PASSWORD.test(newPassword)
                            }
            >
              {t('profile.save')}
            </Button>
          </Grid>

        </Grid>

      </Grid>
    </div>
  )
}
