import axios from 'axios'
import { API_URL } from '../config'

export const getSenior = async () => {
  try {
    const response = await axios.get(`${API_URL}/senior`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getSeniors = async () => {
  try {
    const response = await axios.get(`${API_URL}/seniors/`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.seniors
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateSenior = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/senior`, data, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createSenior = async ({ firstname, lastname }) => {
  try {
    const response = await axios.post(`${API_URL}/seniors`, { firstname, lastname, localization: 'Abbeville', language: 'fr' }, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteSenior = async (seniorId) => {
  try {
    const response = await axios.delete(`${API_URL}/seniors/${seniorId}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
