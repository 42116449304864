import axios from 'axios'
import { API_URL } from '../config'

export const getAlbumsMovies = async () => {
  try {
    const response = await axios.get(`${API_URL}/video_lists`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.video_lists
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getMovies = async ({ id }) => {
  try {
    const response = await axios.get(`${API_URL}/video_lists/${id}/videos`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.videos
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteMovieList = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/video_lists/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateMovieList = async (movieListData, id) => {
  try {
    const response = await axios.put(`${API_URL}/video_lists/${id}`, movieListData, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteMovie = async (listId, movieId) => {
  try {
    const response = await axios.delete(`${API_URL}/video_lists/${listId}/${movieId}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createMovieList = async (newMovieList) => {
  try {
    const response = await axios.post(`${API_URL}/video_lists`, newMovieList, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createMovie = async (newMovie, id) => {
  try {
    const response = await axios.post(`${API_URL}/video_lists/${id}`, newMovie, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
