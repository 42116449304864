import axios from 'axios'
import { API_URL, API_ROOT } from '../config'

export const getCameras = async () => {
  try {
    const response = await axios.get(`${API_URL}/cameras/`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.cameras
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const recordCamera = async ({ name, accountId }) => {
  try {
    const response = await axios.post(
      `${API_ROOT}/record`,
      {
        camera_name: name,
        account_id: accountId
      },
      {
        headers: {
          'X-Api-Key': window.env.REACT_APP_KEY,
          'Content-Type': 'application/json'
        }
      }
    )
    return response.data.record
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateCamera = async ({ id, seniorId, enabled }) => {
  try {
    const response = await axios.put(`${API_URL}/cameras/${id}`, { senior_id: seniorId, enabled: enabled }, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
