import { useState, useEffect } from 'react'
import './CameraDetailPage.scss'
// import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme */, useMediaQuery, Button, FormControl, Select, MenuItem, Avatar, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { KeyboardDoubleArrowLeft } from '@mui/icons-material/'
// import _ from 'lodash'
// import { useTranslation } from 'react-i18next'
import { useTranslation, Trans } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../store/snackbar'
import { updateCameras, updateSeniors } from '../../store/region'
import { updateCamera, getCameras, recordCamera } from '../../api/cameras'
import { getSeniors } from '../../api/senior'
import DialogAreYouSure from '../../components/dialog/DialogAreYouSure/DialogAreYouSure'
import DialogLoading from '../../components/dialog/DialogLoading/DialogLoading'

export default function CameraDetailPage () {
  const matches = useMediaQuery('(max-width:780px)')
  const { t } = useTranslation()
  const { id } = useParams()
  // const theme = useTheme();
  const [camera, setCamera] = useState(null)
  const [openAreYouSure, setOpenAreYouSure] = useState(false)
  const [openAreYouSureSwitch, setOpenAreYouSureSwitch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false)
  const [selectedSenior, setSelectedSenior] = useState()
  const cameras = useSelector(state => state.region.cameras)
  const seniors = useSelector(state => state.region.seniors)
  const dispatch = useDispatch()

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveCamera () {
      try {
        const camera = cameras.find((c) => c.id.toString() === id)
        !didCancel && setCamera(camera) && setSelectedSenior(camera.senior || null)
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'cameras.error.getDetail', severity: 'error' }))
        setIsLoading(true)
      }
    }
    async function retrieveSeniors () {
      try {
        if (seniors.length === 0) {
          const seniors = await getSeniors()
          if (seniors.length > 0) !didCancel && dispatch(updateSeniors(seniors))
        }
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'cameras.error.getDetail', severity: 'error' }))
        setIsLoading(true)
      }
    }

    if (id) {
      Promise.all([retrieveCamera(), retrieveSeniors()])
    }

    return () => { didCancel = true }
  }, [id, cameras, seniors])

  useEffect(() => {
    let didCancel = false
    async function retrieveCameraLists () {
      try {
        // replace id by loggedInUser id
        const cameraLists = await getCameras()
        !didCancel && dispatch(updateCameras(cameraLists))
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'cameras.error.getList', severity: 'error' }))
        dispatch(updateCameras([]))
        setIsLoading(true)
      }
    }

    Promise.all([retrieveCameraLists()])

    return () => { didCancel = true }
  }, [])

  function Iframe (props) {
    return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />)
  }

  if (isLoading || !camera) {
    return (<Loading />)
  }

  const cameraPreview = () => {
    return (
      <div>
        {
            camera.senior
              ? <>
                <p>
                  <Trans
                    i18nKey='cameras.preview.validate'
                    values={{ firstname: camera.senior.firstname, lastname: camera.senior.lastname }}
                    components={[<span className='userName' />]}
                  />
                </p>
                <div className='buttonContainer'>
                  <Button variant='contained' className='button' onClick={() => { setOpenAreYouSureSwitch(true) }}>{t('cameras.switchBaby.title')}</Button>
                </div>
                </>
              : <>
                <p>{t('cameras.noBaby')}</p>
                <div className='buttonContainer'>
                  <Button variant='contained' className='button' onClick={() => { setOpenAreYouSureSwitch(true) }}>{t('cameras.addBaby')}</Button>
                </div>
                </>
        }
      </div>
    )
  }

  const switchBabySelect = () => {
    return (
      <>
        <FormControl fullWidth>
          <Select
            id='select'
            value={selectedSenior}
            onChange={(e) => setSelectedSenior(e.target.value)}
          >
            <MenuItem value={null}>
              <em>{t('cameras.baby.none')}</em>
            </MenuItem>
            {
              seniors.map((s) => <MenuItem value={s}>{s.firstname} {s.lastname}</MenuItem>)
            }
          </Select>
        </FormControl>
        {
          selectedSenior
            ? <p>
                <Trans
                  i18nKey='cameras.switchBaby.text'
                  values={{ firstname: selectedSenior.firstname, lastname: selectedSenior.lastname, cameraName: camera.name }}
                  components={[<span className='userName' />]}
                />
              </p>
            : (selectedSenior !== undefined) && <p>
                  <Trans
                    i18nKey='cameras.switchBaby.undo'
                    values={{ cameraName: camera.name }}
                    components={[<span className='userName' />]}
                  />
                                                </p>
        }
      </>
    )
  }

  const toValidate = async () => {
    try {
      setOpenAreYouSure(false)
      setOpenLoadingDialog(true)
      await recordCamera({ name: camera.name, accountId: camera.senior.id })
      setOpenLoadingDialog(false)
      dispatch(setInfo({ open: true, message: 'cameras.success.record', severity: 'success' }))
    } catch (e) {
      console.log(e)
      dispatch(setInfo({ open: true, message: 'cameras.error.record', severity: 'error' }))
      setOpenLoadingDialog(false)
    }
  }

  const toSwitch = async () => {
    try {
      setCamera({ ...camera, senior: selectedSenior })
      let switchSelected
      if (selectedSenior) {
        switchSelected = await updateCamera({ id: id, seniorId: selectedSenior.id, enabled: camera.enabled })
      } else {
        switchSelected = await updateCamera({ id: id, seniorId: null, enabled: camera.enabled })
      }
      if (switchSelected) {
        setOpenAreYouSureSwitch(false)
        dispatch(setInfo({ open: true, message: 'cameras.success.switchBaby', severity: 'success' }))
      } else {
        dispatch(setInfo({ open: true, message: 'cameras.error.switchBaby', severity: 'error' }))
      }
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'cameras.error.switchBaby', severity: 'error' }))
    }
  }

  const handleEnabled = async (event, camera) => {
    const isEnabled = event.target.checked
    try {
      const camerasTmp = [...cameras]
      const currentCam = camerasTmp.findIndex((c) => c.id === camera.id)
      camerasTmp[currentCam] = camera
      await updateCamera({ id: camera.id, seniorId: camera.senior?.id || null, enabled: isEnabled })
      const cameraLists = await getCameras()
      dispatch(updateCameras(cameraLists))
      setCamera(camera) && setSelectedSenior(camera.senior || null)
      dispatch(setInfo({ open: true, message: isEnabled ? 'cameras.success.enabledCamera' : 'cameras.success.disabledCamera', severity: 'success' }))
    } catch (e) {
      console.log(e)
      dispatch(setInfo({ open: true, message: isEnabled ? 'cameras.error.enabledCamera' : 'cameras.error.disabledCamera', severity: 'error' }))
    }
  }

  return (
    <div className='cameraDetailPage'>
      {
        openLoadingDialog && <DialogLoading open={openLoadingDialog} title={t('loading.title')} content={<div>{t('loading.text')}</div>} />
      }
      {openAreYouSure && <DialogAreYouSure
        open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
        title={t('cameras.record.title')} content={cameraPreview()} onClick={toValidate}
        disabledYes={!camera.senior}
                         />}
      {openAreYouSureSwitch && <DialogAreYouSure
        open={openAreYouSureSwitch} setOpen={() => {
          setOpenAreYouSureSwitch(false)
          setSelectedSenior()
        }}
        title={t('cameras.switchBaby.title')} content={switchBabySelect()} onClick={toSwitch}
        disabledYes={selectedSenior === undefined}
                               />}
      <Grid container alignItems='center'>
        <Grid item sm={3} xs={12}>
          <Grid container justifyContent='flex-start' alignItems='center' className='goBack'>
            <KeyboardDoubleArrowLeft className='icon' />
            <Link to='/cameras'>{t('actions.goBack')}</Link>
          </Grid>
        </Grid>

        <Grid item sm={6} xs={12} className='title'>
          <h1>{camera.name}</h1>
        </Grid>

      </Grid>

      <Grid container justifyContent='space-between' spacing={2} alignItems={matches ? 'center' : 'flex-start'} direction={matches ? 'column-reverse' : 'row'}>
        <Grid item xs={12} sm={9} className='musicList'>
          {camera.enabled &&
            <>
              {t('cameras.preview.title')} :
              <div style={{ position: 'relative', width: '100%', height: '0px', paddingBottom: '56.25%' }}>
                  <Iframe iframe={`<iframe style='position:absolute; left:0; top:0; width:100%; height:100%' src="${camera.previewUrl}/detail" title="${camera.name}" frameborder="0" allowfullscreen></iframe>`} />
              </div>
              <div className='buttonContainer'>
                <Button variant='contained' className='button' onClick={() => setOpenAreYouSure(true)}>{t('cameras.record.button')}</Button>
              </div>
            </>}
          <Grid container justifyContent='center'>
            <Grid item>
              <FormGroup>
                <FormControlLabel control={<Switch checked={camera.enabled} onChange={(e) => handleEnabled(e, camera)} />} label={camera.enabled ? 'Caméra active' : 'Caméra inactive'} />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3} container direction='column' alignItems='center'>
          <h3>{t('cameras.baby.title')}</h3>
          {
            camera.senior
              ? <>
                <Avatar
                  className='avatar'
                  sx={{ width: 200, height: 200 }}
                  alt={`${camera.senior.firstname} ${camera.senior.lastname}`}
                  src={camera.senior.avatar}
                />
                <p>{camera.senior.firstname} {camera.senior.lastname}</p>
                <div className='buttonContainer'>
                  <Button variant='contained' className='button' onClick={() => { setOpenAreYouSureSwitch(true) }}>{t('cameras.switchBaby.button')}</Button>
                </div>
                </>
              : <>
                <p>{t('cameras.noBaby')}</p>
                <div className='buttonContainer'>
                  <Button variant='contained' className='button' onClick={() => { setOpenAreYouSureSwitch(true) }}>{t('cameras.addBaby')}</Button>
                </div>
                </>
          }
        </Grid>
      </Grid>
    </div>
  )
}
