export const RESET_PASSWORD = `${window.env.REACT_APP_KEY}`
export const REGEX_MAIL = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
export const REGEX_PHONE = /\+[0-9]{2,3}(?=.*[0-9]).{9,}$/
export const REGEX_PASSWORD = /(?=.*[a-zA-Z])(?=.*[0-9]).{8,}/
export const JITSI_DOMAIN = `${window.env.REACT_APP_JITSI_DOMAIN}`
export const JITSI_APPID = `${window.env.REACT_APP_JITSI_APPID}`
export const API_URL = `https://${window.env.REACT_APP_API_URL}/api/user/v1`
export const API_ROOT = `https://${window.env.REACT_APP_API_URL}`
export const PUBLIC_URL = `https://${window.env.REACT_APP_URL}`
export const NODE_ENV = 'production'
