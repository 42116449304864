import { useState, useEffect } from 'react'
import './InvitationPage.scss'
import { Grid/*, useTheme, useMediaQuery */, Avatar, TextField, Button, Fab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { KeyboardDoubleArrowLeft, AddAPhoto } from '@mui/icons-material/'
// import Loading from '../../components/loading/Loading';
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateUserInvited, getUserInvited, getToken, getUserLogged } from '../../api/users'
import { getSenior } from '../../api/senior'
import Loading from '../../components/loading/Loading'
import { login } from '../../store/user'
import _ from 'lodash'
import { REGEX_MAIL, REGEX_PASSWORD } from '../../config'
import { setInfo } from '../../store/snackbar'

export default function InvitationPage () {
  // const matches = useMediaQuery('(max-width:780px)');
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { token } = useParams()
  const navigate = useNavigate()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [previewUrl, setPreviewUrl] = useState('')
  const [id, setId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveUser () {
      try {
        const user = await getUserInvited(token)
        !didCancel && setFirstname(user.firstname)
        !didCancel && setLastname(user.lastname)
        !didCancel && setEmail(user.email)
        !didCancel && setPreviewUrl(user.avatar)
        !didCancel && setId(user.id)
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'invitation.error.get' }))
        setIsLoading(false)
      }
    }

    Promise.all([retrieveUser()])
    return () => { didCancel = true }
  }, [token])

  const handleChangePicture = async (event) => {
    const {
      target: {
        files: [file]
      }
    } = event

    const reader = new FileReader()

    reader.onload = function (e) {
      setPreviewUrl(e.target.result)
    }

    reader.readAsDataURL(file) // convert to base64 string
  }

  const onUpdateUser = async () => {
    setIsLoading(true)
    try {
      const userTmp = {
        firstname, lastname, email, password
      }
      if (!previewUrl?.startsWith('http') && previewUrl !== '/avatar.svg') {
        userTmp.avatar = previewUrl
      }

      await updateUserInvited(userTmp, id, token)
      localStorage.setItem('token', token)
      userTmp.id = id
      userTmp.user_type_region = false
      const senior = await getSenior()
      await getToken(userTmp.email, userTmp.password)
      const user = await getUserLogged()
      dispatch(login({ user: user, senior: senior }))
      navigate('/medias')
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'invitation.error.update' }))
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return (<Loading />)
  }

  return (
    <div className='invitationPage'>
      <Grid container alignItems='center'>
        <Grid item sm={3} xs={12}>
          <Grid container justifyContent='flex-start' alignItems='center' className='goBack'>
            <KeyboardDoubleArrowLeft className='icon' />
            <Link to='/'>{t('actions.goBack')}</Link>
          </Grid>
        </Grid>

        <Grid item sm={6} xs={12} className='title'>
          <h1>{t('invitation.title')}</h1>
        </Grid>
      </Grid>
      <Grid container alignItems='center'>
        <Grid item sm={5} xs={12} container direction='column' justifyContent='space-around' alignItems='center'>
          <Grid item>
            <div className='avatarContent'>
              <Avatar
                className='avatar'
                sx={{ width: 200, height: 200 }}
                alt={`${firstname} ${lastname}`}
                src={previewUrl}
              />
            </div>

          </Grid>
          <Grid item>
            <Fab size='small' className='upload' onClick={() => { }}>
              <input accept='image/*' style={{ display: 'none' }} id='icon-button-file' type='file' onChange={event => handleChangePicture(event)} />
              <label htmlFor='icon-button-file'>
                <AddAPhoto />
              </label>
            </Fab>
          </Grid>
        </Grid>
        <Grid item sm={7} xs={12} container alignItems='center' className='info'>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.firstname.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='firstname'
              value={firstname}
              onChange={(event) => setFirstname(event.target.value)}
              fullWidth
              required
              margin='normal'
              className='input'
                            /* helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                            error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                            onKeyPress={onKeyPressMail} */
              variant='outlined'
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.lastname.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='lastname'
              value={lastname}
              onChange={(event) => setLastname(event.target.value)}
              fullWidth
              required
              margin='normal'
              className='input'
                            /* helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                            error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                            onKeyPress={onKeyPressMail} */
              variant='outlined'
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.mail.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              required
              margin='normal'
              className='input'
              helperText={!!email.trim() && !REGEX_MAIL.test(email.trim()) ? t('form.mail.helper') : ''}
              error={!!email.trim() && !REGEX_MAIL.test(email.trim())}
                            /* onKeyPress={onKeyPressMail} */
              variant='outlined'
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className='formLabel'>{t('form.password.label')} *</Grid>
          <Grid item sm={8} xs={12}>
            <TextField
              id='password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              required
              margin='normal'
              className='input'
              helperText={!!password && !REGEX_PASSWORD.test(password) ? t('form.password.helper') : ''}
              error={!REGEX_PASSWORD.test(password)}
              variant='outlined'
              autoFocus
              type='password'
            />
          </Grid>
          <Grid
            item xs={12}
            className='button'
          >
            <Button
              variant='contained'
              onClick={onUpdateUser}
              disabled={
                                _.isEmpty(firstname) ||
                                _.isEmpty(lastname) ||
                                _.isEmpty(email) ||
                                !REGEX_MAIL.test(email.trim()) ||
                                _.isEmpty(password) ||
                                !REGEX_PASSWORD.test(password) ||
                                _.isEmpty(email) ||
                                !REGEX_MAIL.test(email.trim())
                            }
            >
              {t('invitation.save')}
            </Button>
          </Grid>

        </Grid>

      </Grid>
    </div>
  )
}
